import React from 'react';
import ServiceCard03 from '../components/ServiceCard03';

const ServiceContainer04 = () => {
    const ServiceData03 = [
        {
            id: '01',
            img01: "images/icon_box/1.png",
            title: "Backyard Design",
            text: "Florida Luxury Landscape is a full-service design firm, specializing in crafting spectacular backyard designs. We provide a holistic approach encompassing architecture, master planning, urban design, and interior architecture."
        },
        {
            id: '02',
            img01: "images/icon_box/2.png",
            title: "Interior Design",
            text: "At Florida Luxury Landscape, we are not only about exteriors. Our full-service design firm includes a talented team of interior designers who harmonize architecture, master planning, urban design, and interior architecture to create stunning interiors."
        },
        {
            id: '03',
            img01: "images/icon_box/3.png",
            title: "Landscape Design",
            text: "Florida Luxury Landscape, a full-service design firm, excels in creating breathtaking landscapes. Our skills span architecture, master planning, urban design, and interior architecture, providing an integrated approach to landscape design."
        },
        {
            id: '04',
            img01: "images/icon_box/4.png",
            title: "Resource Procurement",
            text: "Florida Luxury Landscape, a full-service design firm, also manages resource procurement. With our expertise in architecture, master planning, urban design, and interior architecture, we ensure all materials align perfectly with your project's vision."
        },
        {
            id: '05',
            img01: "images/icon_box/5.png",
            title: "3D Modeling",
            text: "Utilizing the latest technology, Florida Luxury Landscape offers 3D modeling services. As a full-service design firm, we blend architecture, master planning, urban design, and interior architecture to create detailed and realistic 3D models."
        },
        {
            id: '06',
            img01: "images/icon_box/6.png",
            title: "Blueprint Design",
            text: "At Florida Luxury Landscape, we expertly craft blueprints to guide your design process. Our full-service design firm incorporates architecture, master planning, urban design, and interior architecture into every blueprint we create."
        }
    ]

    return (
        <main className="wrapper">
            <section className="services inner in-service pb-0">
                <div className="container">
                    <div className="row">

                        {
                            ServiceData03.map(data =>
                                <ServiceCard03
                                    key={data.id}
                                    data={data}
                                />)
                        }

                    </div>
                </div>
            </section>

        </main>
    );
};

export default ServiceContainer04;
