import React from 'react';
const PriceContainer01 = () => {
    return (
        <section >
            <div className="container">
                <div className="section-header text-center has_line">
                    <h1 className="text-white">START NOW</h1>
                </div>

                <div className="team_inner">
                    <div className="swiper swiper_team">
    
                        <StripePricingTable/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PriceContainer01;

export const StripePricingTable = () => {
    React.useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);
        
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    
    return (
       <div style={{paddingTop:40, backgroundColor:'#1B1B1B'}}>
           <stripe-pricing-table pricing-table-id="prctbl_1NbXpUCrq2EgtewvE2DaTgrU"
                                 publishable-key="pk_test_51NbXYACrq2EgtewvgKYhsYnCoccEW9VDcVNgDcUqiQIqIYmOJX1gPO9CzZ6AXROzk9HJgD7s2hNwSPZUPfSaxU4o00TstFC7kS">
           </stripe-pricing-table>
       </div>
    );
};
