import React from 'react';
import Paragraph from './Paragraph';
import SubHeading from './SubHeading';

const FormText = () => {
    return (
        <div className="col-lg-5">
            <div className="section-header">
                <SubHeading title="LET'S DISCUSS YOUR NEXT LUXURY LANDSCAPE"></SubHeading>
                <Paragraph text="The expertise at FloridaLandscape.Luxury is comprehensive and unparalleled. Covering all areas in Florida and various landscape styles, our team consists of some of the finest professionals in the luxury landscape design industry. We've classified our services into five primary categories: Residential Estates, Commercial Landscapes, Luxury Resorts, Garden Experiences, and Outdoor Events."></Paragraph>
            </div>
        </div>
    );
};

export default FormText;
