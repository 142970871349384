export const testimonials = [
   {
      testimonial: "With Florida Landscape Luxury, my dream landscape became a reality. Their 3D modeling provided an accurate representation, and their team was responsive and accommodating. My backyard now feels like a luxurious resort. Their service truly brings paradise to your doorstep!",
      author: "Alex G.",
      city: "Naples"
   },
   {
      testimonial: "Florida Landscape Luxury transformed my outdoor space into a sanctuary. Their high-tech process was efficient and the team's talent is unrivaled. My guests can't stop admiring my new, luxurious landscape!",
      author: "Morgan J.",
      city: "Tampa"
   },
   {
      testimonial: "The expertise and innovation at Florida Landscape Luxury are unmatched. From the easy planning process to the final execution, everything was perfect. My property has been transformed into an outdoor oasis, all thanks to them!",
      author: "Sophia B.",
      city: "Miami"
   }
]
